.persons {
    --theme-color: #d59c00;
}

.events {
    --theme-color: #ce7c20;
}

.places {
    --theme-color: #db487f;
}

.fossils {
    --theme-color: #5ba726;
}

.signs {
    --theme-color: #c12e2e;
}

*, *::after, *::before {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.map-boby {
    position: relative;
    width: 100%;
    min-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media screen and (min-width: 900px) and (max-width: 1800px) {
  .map-boby .main,
  .map-boby .header {
    /*transform: scale(0.8);*/
    zoom: 70%;
  }

  .right-side {
    bottom: 10vh !important;
  }
}

.opacity-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #00000012;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1.9px;
}

.map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(32, 33, 36, 0.6);
}

.with-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.with-icon::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.icon-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    pointer-events: none;
}

.eye {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.eye::before {
    content: "";
    display: block;
    width: 22px;
    height: 16px;
    opacity: .5;
    background: url("../../assets/imgs/eye.svg") no-repeat center/contain;
}

.eye::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -4px;
    -webkit-transform: translateY(-50%) rotate(-45deg);
    transform: translateY(-50%) rotate(-45deg);
    display: block;
    width: 100%;
    height: 1px;
    background-color: #ff0000;
}

.custom-scroll {
    scrollbar-color: #888888 transparent;
    scrollbar-width: thin;
}

.custom-scroll::-webkit-scrollbar {
    width: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 4px;
    border: 10px solid rgba(32, 33, 36, 0.6);
}

.custom-scroll2 {
    scrollbar-color: #888888 transparent;
    scrollbar-width: thin;
}

.custom-scroll2::-webkit-scrollbar {
    width: 1px;
    height: 1px;
}

.custom-scroll2::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.show-checkbox:checked + .eye::before {
    opacity: 1;
}

.show-checkbox:checked + .eye::after {
    content: none;
}

.main {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-height: calc(100vh - 124px);
    pointer-events: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.main > * {
    pointer-events: all;
}

.main__tabs-wrapper {
    position: relative;
    overflow: hidden;
    height: 0;
}

.main__tabs-wrapper.active {
    height: 526px;
    overflow: unset;
}

.main__tab img,
.main__tab .lampIcon svg {
  width: 12px;
  height: 12px;
  stroke: var(--main-color);
}

.main__tab {
    position: relative;
    display: block;
    width: 60px;
    height: 60px;
    background-color: #fff;
    /*overflow: hidden;*/
    margin-bottom: 1px;
}

.main__tab img,
.main__tab svg {
    width: 24px;
    height: 24px;
    stroke: var(--main-color);
}

.main__tab-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.main__tab.active {
    background-color: var(--main-color);
    overflow: unset;
}
.main__tab.active .main__tab-btn svg,
.main__tab.active > svg {
    stroke: #fff;
}

.main__tab.active .main__subitem {
    width: 407px;
    height: 243px;
    padding-bottom: 14px;
}


.main__tab.active .main__subitem-title::before {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background-color: var(--theme-color);
}

.main__subitem {
    position: absolute;
    top: 0;
    left: 61px;
    width: 0;
    height: 0;
    border-radius: 0 12px 12px 0;
    background-color: #fff;
    overflow: hidden;
}

.main__subitem-heading {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 1;
    height: 60px;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 2px;
    padding-left: 25px;
}

.main__subitem-title {
    position: relative;
    z-index: 1;
    font-size: 16px;
}

.main__subitem-libtn {
    text-align: left;
    width: 100%;
}

.main__subitem-btn {
    width: 100%;
    font-size: 13px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-align: left;
    line-height: 1;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 0;
}

.main__subitem-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.main__subitem-label {
    position: relative;
    display: flex;
    z-index: 1;
    margin-left: auto;
    /*margin-right: 31px;*/
}

.main__subitem-heading .main__subitem-label {
    margin-right: 36px;
}

.main__subitem-label input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.main__subitem-itemswrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    height: calc(100% - 35px);
    padding: 10px;
}

.main__subitem-items {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.main__subitem-initem {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 6px;
}


.main__subitem-initem .main__subitem-label {
    margin-right: 10px;
}

.main__subitem-item {
    width: calc(100% - 10px);
}

.main__subitem-item.active .main__subitem-list {
    width: 100%;
    height: 100%;
}

.main__subitem-item.active .main__subitem-arrow {
    transform: rotateX(180deg);
}

.main__subitem-list {
    overflow: hidden;
    width: 0;
    height: 0;
    margin-left: 32px;
    padding: 5px 0;
}

.main__subitem-li {
    display: flex;
    align-items: center;
    padding: 0 10px 0 25px;
    width: calc(100% - 62px);
    font-size: 13px;
}

.main__subitem-li:not(:last-child) {
    margin-bottom: 5px;
}

.main__subitem-li.active {
    background-color: var(--theme-color);
}


.main__subitem-close {
    display: block;
    width: 34px;
    height: 34px;
    opacity: .7;
}

.main__subitem-close > span {
    display: block;
    transform: rotate(45deg);
    margin-right: 10px;
}

.left-side {
    margin-top: 100px;
    position: relative;
    width: max-content;
}

.left-side__bottom {
    position: relative;
    height: 222px;
}

.left-side__hints {
    margin-top: 36px;
}

.right-side {
    position: absolute;
    right: 0;
    bottom: 30vh;
    width: 60px;
    height: max-content;
}

.right-side__search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.right-side__search .search-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
}

.right-side__search-field {
    position: relative;
    border-bottom: 1px solid var(--main-color);
    margin: 0 15px;
}

.right-side__search-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.right-side__search-input, .right-side__search-filters {
    display: block;
    height: 50px;
    width: 100%;
}

.right-side__search-input {
    padding: 0 60px 0 25px;
}

.right-side__search-input::placeholder {
    color: inherit;
    font: inherit;
  }

.right-side__search-content {
    position: absolute;
    top: 0;
    right: 61px;
    width: 0;
    height: 0;
    background-color: #fff;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
}

.right-side__search-filters {
    padding: 0 20px;
}

.right-side__search.active .right-side__search-content {
    height: auto;
    width: 550px;
}

.right-side__search.active .main__subitem {
    position: relative;
    width: 100%;
    left: unset;
    right: 0;
    top: unset;
    bottom: 0;
    height: 275px;
    border-top-right-radius: 0;
    background-color: transparent;
    padding-bottom: 0;
    overflow-y: auto;
}

.right-side__search.active .main__subitem::before, .right-side__search.active .main__subitem::after {
    content: none;
}

.right-side__search.active .main__tab-btn {
    background-color: var(--main-color);
}

.right-side__search.active .right-side__search-response {
    position: relative;
    height: 275px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}


.right-side__filters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
}

.right-side__search-content .with-svg {
    width: 100%;
    height: 100%;
}

.right-side__filters-btn {
    font-size: 13px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 0;
}

.right-side__filters-btn img,
.right-side__filters-btn svg {
    width: 15px;
    height: 15px;
    margin-right: 7px;
}

.right-side__filters-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    overflow: hidden;
    padding: 4px 10px;
}

.right-side__filters-item.actv {
    background-color: #e9ecef;
}

.right-side__filters-item:not(:last-child) {
    /*margin-right: 13px;*/
}


.header__right .filters.active .main__tab-btn {
    background-color: var(--main-color);
}

.header__right .filters__content {
    left: unset;
    right: 0;
    top: 60px;
    border-radius: 12px 0 0 12px;
}


.filters {
    overflow: hidden;
}

.filters__btn {
    position: relative;
}

.filters__content {
    position: absolute;
    top: 0;
    left: 61px;
    height: 0;
    width: 0;
    border-radius: 0 12px 12px 0;
    background-color: #fff;
    overflow: hidden;
}


.filters.active .left-side__tab {
    background-color: #218cff;
}

.filters.active .filters__content {
    width: 260px;
    height: auto;
    padding: 10px 15px;
    overflow: unset;
}

.filters__title {
    font-size: 13px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 12px;
}

.filters__radio {
    border: none;
}

.filters__radio-label {
    position: relative;
    display: block;
    text-align: center;
    border: 1px solid var(--main-color);
    border-radius: 15px;
    overflow: hidden;
}

.filters__radio-label:not(:last-child) {
    margin-bottom: 10px;
}

.filters__radio-label input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.filters__radio-label input:checked + .filters__radio-text {
    background-color: var(--main-color);
    color: #fff;
}

.filters__radio-text {
    display: block;
    width: 100%;
    height: 100%;
    padding: 3px;
}

.response {
    padding: 10px 10px 0 20px;
}

.response__items::after {
    content: '';
    display: block;
    width: 100%;
    height: 20px;
}

.response__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 0;
}

.response__item-icon {
    margin-right: 5px;
}

.articles-grid {
    margin-top: 30px;
    right: 0;
    width: auto;
    /* max-width: 450px; */
    overflow: hidden;
    max-height: 400px;
    height: 100%;
    display: grid;
    grid-template-rows: repeat(3, 80px);
    grid-auto-flow: column;
    grid-auto-columns: auto;
    column-gap: 15px;
}

.hero {
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 450px;
    overflow: hidden;
    max-height: 360px;
    height: 100%;
    z-index: 2;
}

.hero.minimized {
    position: relative;
    max-height: 63px;
    max-width: 350px;
    min-width: 295px;
    width: 100%;
    overflow: hidden;
    height: 100%;
    margin-bottom: 20px;
    z-index: 1;
}


.hero.minimized .hero__media {
    pointer-events: none;
    float: none;
    margin: 0;
    margin: 0 auto;
}


.hero.minimized .hero__more,
.hero.minimized .hero__audio,
.hero.minimized .hero__category-btns,
.hero.minimized .hero__category,
.hero.minimized .hero__more,
.hero.minimized .hero__text,
.hero.minimized .hero__bottom {
    display: none;
}

.hero.minimized .hero__media {
    width: 35px;
    height: 35px;
}

.hero.minimized .hero__info {
    /*display: flex;*/
    width: 79%;
}

.hero.minimized .hero__header {
    width: 100%;
    margin-bottom: 0;
}

.hero__media img {
    /*width: fit-content;*/
    height: 100%;
    display: block;
    object-fit: cover;
    width: 100%;
}


.hero.minimized .hero__collapse {
    margin-right: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.hero__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    grid-area: h;
    order: 2;
    gap: 10px;
}

.hero__text {
    padding-right: 20px;
}

.hero__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.hero__btns button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hero__collapse {
    margin-right: 5px;
    width: 25px;
    height: 25px;
}

.hero__close {
    transform: rotate(45deg);
    width: 25px;
    height: 25px;
}

.hero__title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    opacity: 1;
    font-size: 17px;
    letter-spacing: 0.75px;
    color: rgba(44,71,108,1);
    font-family: "NotoSansArmenian-Bold";
    font-weight: 700;
    font-style: normal;
    text-align: left;
    text-transform: uppercase;
    min-width: 100px;
    line-height: 20px;
}

.hero__descr {
    /*line-height: 1.7;*/
    max-height: 250px;
    overflow-y: scroll;
    /*font-size: 12px;*/
    opacity: 1;
    color: rgba(16,26,41,1);
    font-family: "NotoSansArmenian-Regular";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 17px;
}


.hero__descr::-webkit-scrollbar {
    width:20px;
}

/* Track */
.hero__descr::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
.hero__descr::-webkit-scrollbar-thumb {
    background: #2c476c;
    padding: 0 4px;
    border-top: 7px solid transparent;
    border-bottom:7px solid transparent;
    border-right: 7px solid transparent;
    border-left:7px solid transparent;
    background-clip: padding-box;
    border-radius: 10px;
}

/* Handle on hover */
.hero__descr::-webkit-scrollbar-thumb:hover {
    background: #2c476c;
    border-radius: 10px;
}

.hero__media-wrapper {
    grid-area: m;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    justify-content: space-around;
    gap: 10px;
}

.hero__media {
    position: relative;
    width: 135px;
    height: 135px;
    margin-bottom: 20px;
    border-radius: 50%;
    overflow: hidden;
}

.hero__media-gallery {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero__info {
    grid-area: i;
    font-size: 11px;
    width: 100%;
}

.hero__info-wrapper {
    position: relative;
    background-color: #fff;
    display: grid;
    align-items: stretch;
    padding: 15px 20px;
    grid-template-areas: "h h""m i";
    border-radius: 16px;
    opacity: 1;
    background-color: rgba(255,255,255, 1);
}
.minimized .hero__info-wrapper{
    padding: 5px 20px;
    grid-template-areas: "m h h";
    align-items: center;
}
.hero__media_icons{
    display: flex;
    gap:10px;
}
.hero.minimized .hero__media_icons,.hero.minimized .hero__media-items{
    display: none;
}

.hero__more {
    background-color:#FFDE5A;
    display: block;
    width: 100%;
    max-width: 135px;
    height: 28px;
    border-radius: 14px;
    border-radius: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: rgba(0,0,0,1);
    font-family: "NotoSansArmenian-Regular";
    font-weight: 600;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    text-transform: uppercase;
    transition: .3s;
}

.hero__more:hover {
    background-color:#FFCC01;
}

.hero__audio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 50px;
    /*background-color: rgba(44, 71, 108, .9);*/
    background-color: #eaedf0;
    font-size: 12px;
    /*color: #fff;*/
    color: #2C476C;
    position: absolute;
    bottom: 0;
}

.hero__audio-play {
    flex-shrink: 0;
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    /*margin-left: 20px;*/
    border-radius: 50%;
    /*border: 1px solid rgba(255, 255, 255, .3);*/
    border: 1px solid #b4bdca;
}

.hero__audio-play svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    /*fill: #fff;*/
    -webkit-transition: opacity .4s ease;
    transition: opacity .4s ease;
    fill: RGB(44, 71, 108);
}

.hero__audio-play .audio-pause {
    visibility: visible;
    opacity: 1;
}

.hero__audio-play .audio-pause.paused {
    opacity: 0;
    visibility: hidden;
}

.hero__audio-play .audio-play {
    opacity: 1;
    visibility: visible;
}

.hero__audio-play .audio-play.paused {
    visibility: hidden;
    opacity: 0;
}

.hero__audio-currtime {
    font-family: 'Avenir Next';
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 20px;
}

.hero__audio-track {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-appearance: none;
    /*background-color: rgba(255, 255, 255, .3);*/
    border-radius: 0;
    height: 2px;
}
.hero__audio-track::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    opacity: 0;
}
.hero__audio:hover .hero__audio-track::-webkit-slider-thumb {
    opacity: 1;
}
.hero__audio-duration {
    font-family: 'Avenir Next';
    display: block;
    margin: 0 20px;
}

.hero__audio-volume {
    position: relative;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
}

/*.hero__audio-volume:hover {*/
/*    width: 60px;*/
/*}*/
/*.hero__audio-volume:hover .volume-track {*/
/*    z-index: 1;*/
/*}*/

.hero__audio-volume .volume-track {
    position: absolute;
    height: 60px;
    width: 100%;
    /*background: #38567a;*/
    /*bottom: 25px;*/
    /*left: -5px;*/
    bottom: -5px;
    left: 5px;
    padding: 10px;
}
.css-b9pdga-MuiSlider-root {
    padding: 0 !important;
}
.hero__audio-volume .volume-track input {
    height: 2px;
    width: 100%;
    -webkit-appearance: none;
    background-color: #fff;
}
.hero__audio-volume .volume-track input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

.hero__audio-volume .volume-btn {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 20px;
    height: 20px;
    z-index: 1;
    /*margin-right: 8px;*/
}
.hero__audio-volume .volume-btn svg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
}
.hero__audio-volume .volume-btn.muted {
    position: relative;
}

.hero__audio-volume .volume-btn.muted svg {
    opacity: .4;
}

.hero__audio-volume .volume-btn.muted::before {
    content: '';
    width: 35px;
    height: 1px;
    background-color: #ff0000;
    position: absolute;
    left: 47%;
    top: 47%;
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
}

.hero__category {
    margin-bottom: 10px;
}

.hero__category-pages {
    opacity: .6;
}

.hero__category-btns {
    font-family: 'Lucida Grande';
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    margin-bottom: 5px;
    height: 15px;
}

.hero__category-btns button {
    display: block;
    width: 15px;
    height: 15px;
    font-size: 12px;
}

.hero__category-prev svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.hero__category-btns button:disabled {
    cursor: not-allowed;
    opacity: .6;
}

.hints {
    position: relative;
    margin-top: 20px;
    overflow: hidden;
}
.hints.hints.active {
    overflow: unset;
}

.hints__content {
    position: absolute;
    left: 61px;
    top: 0px;
    height: 0;
    width: 0;
    border-radius: 0 12px 12px 0;
    background-color: #fff;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}


.hints__content-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
}

.hints__content-img {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.hints__content-img img {
    height: 100%;
}

.hints__content-name {
    text-align: left;
    font-size: 12px;
    margin-bottom: 10px;
}
.hints__content-text {
    max-width: 150px;
}

.hints__content-bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
}

.hints__content-bottom span {
    font-weight: 500;
    color: #d59c00;
}
.hints__content-bottom svg {
    margin: 0 19px;
}


.hints__close {
    position: absolute;
    right: 12px;
    top: 9px;
}

.hints.active .hints__content {
    width: 254px;
    height: 121px;
    padding: 7px 10px;
}
.splide__slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.hints__title {
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    letter-spacing: 2px;
    margin-bottom: 12px;
}

.hints__radio {
    border: none;
}

.hints__radio-label {
    position: relative;
    display: block;
    text-align: center;
    border: 1px solid #888888;
    border-radius: 8px;
    overflow: hidden;
}

.hints__radio-label:not(:last-child) {
    margin-bottom: 8px;
}

.hints__radio-label input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.hints__radio-label input:checked + .filters__radio-text {
    background-color: #218cff;
}

.hints__radio-text {
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: rgba(32, 33, 36, 0.6);
}

.hints .splide__arrows {
    position: absolute;
    bottom: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 34px;
    height: 13px;
    left: 190px;
}

.hints .splide__arrows svg {
    display: none;
}

.hints .splide__arrows button {
    width: 13px;
    height: 13px;
    background-color: transparent;
    background: url("../../assets/imgs/icons/Arrow-active.svg") no-repeat center/contain;
    opacity: 1;
}

.hints .splide__arrows button:disabled {
    cursor: not-allowed;
    opacity: .5;
}

.hints .splide__arrow--prev {
    left: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
}

.hints .splide__arrow--next {
    right: 0;
}
.zoom-btns .main__tab:first-child {
    margin-bottom: 10px;
}

.zoom-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.lesson {
    position: relative;
    overflow: hidden;
    display: -ms-grid;
    display: grid;
    height: 44px;
}

.lesson-back {
    position: absolute;
    z-index: 2;
    left: 0;
    top: -61px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    white-space: nowrap;
    background-color: #fff;
    transform: rotate(180deg);
}

.lesson__names {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: -ms-grid;
    display: grid;
    z-index: 1;
}

.lesson__name {
    width: 100%;
    background-color: var(--main-color);
    font-size: 14px;
    padding: 8px 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 2;
    color: white;
    text-align: center;
    text-transform: uppercase;
}

.lesson__prev {
    text-align: right;
}

.lesson__next {
    text-align: left;
}

.lesson__current {
    text-align: center;
}

.lesson__content-catwrap {
    max-height: 145px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 35px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
}

.lesson__content-cat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.lesson__content-cat > .main__subitem-label {
    flex-shrink: 0;
    padding: 0 10px;
    display: flex;
    place-items: center;
}

.lesson__content-cat > .main__subitem-label .eye::before {
    margin-right: unset;
}

.lesson__content-cat > .main__subitem-label .eye::after {
    left: 0;
}

/*.lesson__content-cat:not(:last-child) .lesson__content-items {*/
/*    border-bottom: 1px solid #888888;*/
/*}*/

.lesson__content-items {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow-x: auto;
    padding-right: 10px;
}

.lesson__content-svg {
    flex-shrink: 0;
    width: 60px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 5px;
}
.lesson__content-item {
    padding: 5px 15px;
    color: #ffffff;
    border-radius: 15px;
    margin-right: 10px;
    cursor: pointer;
}
.lesson__content-name img {
    filter: brightness(10)
}

.main__tab.active .main__tab-btn .main__tab-icon img {
    filter: brightness(10);
}
.lesson__content-item .main__subitem-label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--theme-color);
    padding: 5px 10px;
    border-radius: 3px;
    margin-left: 10px;
}

.lesson__content-name {
    display: flex;
    align-items: center;
    line-height: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 5px;
}

.lesson__content-name svg {
    margin-right: 5px;
    margin-top: -3px;
}
.lesson__content-item .main__subitem-label .eye {
    border-right: 1px solid #464646;
}

.lesson__show {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(32, 33, 36, 0.6);
}

.lesson__show-inner {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: -ms-grid;
    display: grid;
    place-items: center;
}

.lesson__show-text {
    margin-top: 44px;
    margin-bottom: 24px;
    font-weight: 500;
}

.lesson__show-label {
    position: relative;
}

.lesson__show-label .eye::before {
    width: 40px;
    height: 30px;
}

.lesson__show-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.lg-icon {
    display: block;
    width: 48px;
    height: 48px;
    background-color: transparent;
    background: url("../../assets/imgs/arrow.svg") no-repeat center/contain;
}

.lg-icon::before, .lg-icon::after {
    content: none;
}

.element.style {
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
}

.lg-backdrop.in {
    opacity: 1;
}

.lg-backdrop.in {
    opacity: .7 !important;
}

.lg-outer {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.lg-content {
    max-width: 1292px;
    left: 50% !important;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.lg-outer .lg-thumb-outer {
    background-color: transparent;
    float: unset;
    margin: 0 auto;
    width: 92.5%;
}

.lg-prev {
    left: -50px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.lg-next {
    right: -50px;
}

.lg-download {
    display: none !important;
}

.lg-counter {
    opacity: 0;
    visibility: hidden;
}

.lg-toolbar .lg-icon {
    color: #fff;
}

.mapboxgl-canvas {
    width: 100% !important;
    height: 100% !important;
    left: 0;
}

.mapboxgl-popup {
    max-width: 400px;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.mapboxgl-popup-close-button {
    display: none !important;
}

.mapboxgl-popup-content {
    border-radius: 200px !important;
    padding: 0 !important;
    background: transparent !important;
    box-shadow: none !important;
}

.mapboxgl-popup-anchor-bottom > .mapboxgl-popup-tip {
    display: none;
}

textarea:focus, input:focus{
    outline: none;
}


.tic_animation {
  animation: beat 0.5s infinite alternate;
}

/* Heart beat animation */
@keyframes beat {
  to {
    width: 38px;
    height: 38px;
  }
}